<template>
	<ZyroModal
		max-width="351px"
		max-height="565px"
		:title="$t('common.addNewLink')"
		class="add-link-modal"
		@close-modal="closeModal"
	>
		<LinkSettings
			base-target="_self"
			:label="$t('builder.pageSettingsModal.textFields.nameInNavigation.label')"
			base-href=""
			:base-content="$t('common.newLink')"
			:validate="validate"
			@settings-update="linkSettingsData = {
				...linkSettingsData,
				...$event
			}"
		/>
		<ZyroFieldToggle
			id="addLinkModal-toggle"
			:checked="hidden"
			:label="$t('common.hidePage')"
			@change="hidden = !hidden"
		/>
		<template slot="footer">
			<ZyroButton @click="closeModal">
				{{ $t('common.cancel') }}
			</ZyroButton>
			<ZyroButton
				theme="primary"
				data-qa="addlinkmodal-btn-save"
				@click="submitForm"
			>
				{{ $t('common.save') }}
			</ZyroButton>
		</template>
	</ZyroModal>
</template>

<script>
import { mapActions } from 'vuex';

import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { NAVIGATION_LINK_TYPE } from '@/use/navigation/constants';

export default {
	components: { LinkSettings },
	data() {
		return {
			linkSettingsData: {
				href: '',
				isUrlValid: true,
				target: '',
				content: '',
			},
			hidden: false,
			validate: false,
		};
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		...mapActions('navigation', ['addItem']),
		submitForm() {
			if (!this.linkSettingsData.isUrlValid) {
				this.validate = true;

				return;
			}

			this.addItem({
				item: {
					name: this.linkSettingsData.content || this.$t('common.newLink'),
					url: this.linkSettingsData.href,
					target: this.linkSettingsData.target,
					type: NAVIGATION_LINK_TYPE,
					subItems: [],
				},
				isHidden: this.hidden,
			});
			this.closeModal();
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .modal .modal__content {
	overflow: visible;
}
</style>
