<template>
	<div class="link-settings">
		<ZyroFieldText
			v-if="!noContent"
			:id="`${elementId}-text`"
			v-model="content"
			v-qa="'linksettingsmodal-inputfield-inputname'"
			:label="label"
			:placeholder="$t('common.newLink')"
		/>
		<ZyroLabel>{{ $t('builder.editButton.linkSettings.label') }}</ZyroLabel>
		<ZyroSegmentControl
			:controls="$options.tabs"
			:active-control="currentTab"
			class="segment-control"
			@change="currentTab = $event"
		/>
		<ZyroFieldText
			v-if="currentTab.tabId !== 'page'"
			:id="`${elementId}-link`"
			v-model="href"
			v-qa="`linksettingsmodal-inputfield-${currentTab.tabId}`"
			:placeholder="$options.placeholders[currentTab.tabId]"
			:label="currentTab.title"
			:error="isDirtyHref && !isUrlValid ? currentTab.errorMessage : ''"
		/>
		<ZyroFieldToggle
			v-if="currentTab.tabId === 'url'"
			:id="`${elementId}-toggle`"
			v-qa="'linksettingsmodal-inputtoggle-openinnewtab'"
			:checked="target === '_blank'"
			:label=" $t('builder.editButton.linkSettings.toggleFieldLabel')"
			@change="toggle"
		/>
		<template v-if="currentTab.tabId === 'page'">
			<ZyroLabel class="dropdown__label">
				{{ currentTab.title }}
			</ZyroLabel>
			<ZyroDropdown
				v-qa="'linksettingsmodal-inputdropdown-page'"
				:options="pages"
				:current.sync="currentItem"
			/>
		</template>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import {
	mapState,
	mapGetters,
} from 'vuex';

import i18n from '@/i18n/setup';
import { capitalizeFirstLetter } from '@/utils/modifyString';
import {
	getValidUrl,
	getValidPhone,
	getValidEmail,
	stripUrl,
} from '@/utils/urlValidators';

// TODO: add error messages (important: consult with copywriters)
const tabs = [
	{
		title: i18n.t('common.page'),
		tabId: 'page',
	},
	{
		title: i18n.t('common.url'),
		tabId: 'url',
		validator: getValidUrl,
		errorMessage: i18n.t('validate.url'),
	},
	{
		title: i18n.t('common.phone'),
		tabId: 'phone',
		validator: getValidPhone,
		errorMessage: i18n.t('validate.phone'),
	},
	{
		title: i18n.t('common.email'),
		tabId: 'email',
		validator: getValidEmail,
		errorMessage: i18n.t('validate.email'),
	},
];

const placeholders = {
	url: 'https://www.example.com',
	phone: i18n.t('common.phoneExample'),
	email: 'example@example.com',
};

export default {
	tabs,
	placeholders,
	props: {
		elementId: {
			type: String,
			default: nanoid(),
		},
		label: {
			type: String,
			default: '',
		},
		baseTarget: {
			type: String,
			default: '_self',
		},
		baseHref: {
			type: String,
			default: '',
		},
		baseContent: {
			type: String,
			default: '',
		},
		noContent: {
			type: Boolean,
			default: false,
		},
		validate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isUrlValid: true,
			currentTab: null,
			currentItem: null,
			validator: null,
			target: '',
			tempContent: '',
			isDirtyHref: false,
			currentUrl: {
				url: '',
				phone: '',
				email: '',
			},
		};
	},
	computed: {
		...mapState(['website']),
		...mapGetters('pages', ['defaultPages']),
		pages() {
			return Object.entries(this.defaultPages).map(([pageId, page]) => ({
				pageId,
				title: page.name
					|| capitalizeFirstLetter(page.path.slice(1))
					|| 'Home',
			}));
		},
		content: {
			get() {
				return this.tempContent;
			},
			set(event) {
				this.tempContent = event.target.value;
			},
		},
		href: {
			get() {
				return stripUrl(this.currentUrl[this.currentTab.tabId]).replace(/^\/\//, '');
			},
			set(event) {
				const { value } = event.target;
				const validationResult = this.validator(value);

				this.isUrlValid = validationResult.isUrlValid;
				this.currentUrl[this.currentTab.tabId] = validationResult.url;
				if (!this.isDirtyHref) {
					this.isDirtyHref = true;
				}

				this.onChange(this.currentUrl[this.currentTab.tabId], this.target, this.isUrlValid);

				return value;
			},
		},
	},
	watch: {
		validate(newValue) {
			if (newValue) {
				this.isDirtyHref = true;
			}
		},
		currentTab() {
			const isPage = this.currentTab.tabId === 'page';

			if (!isPage) {
				const { tabId } = this.currentTab;

				this.validator = this.currentTab.validator;
				const validationResult = this.validator(this.currentUrl[tabId]);

				this.isUrlValid = validationResult.isUrlValid;
				this.currentUrl[tabId] = validationResult.url;
			} else {
				this.isUrlValid = true;
			}

			switch (this.currentTab.tabId) {
			case 'page':
				this.target = '_self';
				break;
			case 'url':
				this.target = this.baseTarget || '_self';
				break;
			default:
				this.target = '_blank';
			}

			const updatedUrl = isPage ? this.currentItem.pageId : this.currentUrl[this.currentTab.tabId];

			this.onChange(updatedUrl, this.target, this.isUrlValid);
		},
		currentItem() {
			if (this.currentTab.tabId === 'page') {
				this.onChange(this.currentItem.pageId, this.target, this.isUrlValid);
			}
		},
		tempContent() {
			this.$emit('settings-update', { content: this.tempContent });
		},
	},
	beforeMount() {
		this.currentItem = this.findPage(this.baseHref) || this.pages[0];
		const currentTab = this.getCurrentTabByHref(this.baseHref);

		this.currentUrl[currentTab.tabId] = this.baseHref;
		this.currentTab = currentTab;
		this.tempContent = this.baseContent;
	},
	methods: {
		getCurrentTabByHref(href) {
			const findTab = (tabId) => this.$options.tabs.find((tab) => tab.tabId === tabId);
			const strippedHref = stripUrl(href);

			if (this.findPage(href)) {
				return findTab('page');
			}

			if (getValidPhone(strippedHref).isUrlValid) {
				return findTab('phone');
			}

			if (getValidEmail(strippedHref).isUrlValid) {
				return findTab('email');
			}

			return findTab('url');
		},
		findPage(pageValue) {
			return this.pages.find((page) => page.pageId === pageValue);
		},
		onChange(href, target, isUrlValid) {
			this.$emit('settings-update', {
				href,
				target,
				isUrlValid,
			});
		},
		toggle() {
			this.target = this.target === '_blank' ? '_self' : '_blank';
			this.$emit('settings-update', { target: this.target });
		},
	},
};
</script>
<style lang="scss" scoped>
.segment-control {
	margin-top: 8px;
	margin-bottom: 24px;
}

.dropdown__label {
	margin: 8px 0;
}
</style>
